import React from 'react'

export default (props) => {
	return (

		<figure>
			<img class={"honbun_img lazyload " + props.tuika_class}
				alt={props.alt}
				src={"https://elvn.imgix.net/"+props.path+"?w=50&auto=format,compress&q=0"}
				data-src={"https://elvn.imgix.net/" + props.path + "?w=944&auto=format,compress&q=15"}
				style={props.tuika_style}
			/>
			<figcaption>{props.caption}</figcaption>
		</figure>
	)
}