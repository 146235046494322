import React from 'react'

export default (props) => {
	return (

		<div class={"resp_video kage " + props.tuika_class}>
			<iframe class="lazyload" src={"https://www.youtube.com/embed/videoseries?list="+props.bangou+""} frameborder="0" allow="accelerometer; autoplay; encrypted-media; fullscreen; gyroscope; picture-in-picture" allowfullscreen></iframe>
		</div>

	)
}