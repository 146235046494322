import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`センスも才能もなしに人を感動させるには？`}<br parentName="p"></br>{`
`}{`MIXできないのは僕らのせいじゃない！`}<br parentName="p"></br>{`
`}{`なぜMIXができるようになったのか？`}<br parentName="p"></br>{`
`}{`MIXできるようになる「金額」は？`}<br parentName="p"></br>{`
`}{`巷のテクニックが全く無意味な理由とは？`}<br parentName="p"></br>{`
`}{`才能は本当に無関係か？`}<br parentName="p"></br>{`
`}{`間違いだらけの「僕らの努力」！`}<br parentName="p"></br>{`
`}{`…`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      