import { MDXProvider } from "@mdx-js/react";
import { Link } from "gatsby";
import Kiji from "@components/kiji-link";
import KijiLink from "@components/kiji-link-only";
import Youtube from "@components/youtube";
import YoutubeList from "@components/youtube-list";
import SEO from '@components/seo';
import lazysizes from 'lazysizes';
import '@layouts/global.css';
import Header from '@components/header';
import Footer from '@components/footer';
import H2Back from '@components/h2-back';
import Naiyou from "@components/md-components/naiyou";
import Touroku from "@components/touroku";
import * as React from 'react';
export default {
  MDXProvider,
  Link,
  Kiji,
  KijiLink,
  Youtube,
  YoutubeList,
  SEO,
  lazysizes,
  Header,
  Footer,
  H2Back,
  Naiyou,
  Touroku,
  React
};