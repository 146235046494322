import React from "react"
import Para from 'react-rellax'


export default () => (

<Para as={'img'} speed={2} percentage={0}
	  class="h2_img lazyload para" alt=""
	  src="https://elvn.imgix.net/article/h2/hosi-spark-l.jpg"
	  data-src="https://elvn.imgix.net/article/h2/hosi-spark.jpg?w=1400&auto=format&fm=jpg&q=1&cs=srgb"/>
)