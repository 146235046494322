import React from "react"
import { Link } from "gatsby"


export default () => (
<>

	<header id="header">

		<div class="slit w100"></div>
		<div class="flex f-center">
			<Link to="/"><h1 class='site_title'>E.L.V.N</h1></Link>
		</div>

	</header>


	<img class="topuga lazyload" alt="" width="400" height="148"
		  src="https://elvn.imgix.net/global/topugaMobi.png?w=400&auto=format,compress&q=0"
		  data-src="hhttps://elvn.imgix.net/global/topugaMobi-mid.png?w=1927&auto=format&fm=jpg&q=35&cs=srgb"
		  data-srcset="https://elvn.imgix.net/global/topugaMobi-small.png?w=1360&auto=format&fm=jpg&q=35&cs=srgb 1242w,
					   https://elvn.imgix.net/global/topugaMobi-mid.png?w=1927&auto=format&fm=jpg&q=35&cs=srgb 1668w,
					   https://elvn.imgix.net/global/topugaDesk-large.png?w=2833&auto=format&fm=jpg&q=45&cs=srgb 1669w"
	/>

</>
)