import { Link } from "gatsby";
import Kiji from "@components/kiji-link";
import KijiLink from "@components/kiji-link-only";
import Youtube from "@components/youtube";
import YoutubeList from "@components/youtube-list";
import Gazou from "@components/gazou";
import * as React from 'react';
export default {
  Link,
  Kiji,
  KijiLink,
  Youtube,
  YoutubeList,
  Gazou,
  React
};