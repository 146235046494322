import Kiji from "@components/kiji-link";
import KijiLink from "@components/kiji-link-only";
import Youtube from "@components/youtube";
import Gazou from "@components/gazou";
import * as React from 'react';
export default {
  Kiji,
  KijiLink,
  Youtube,
  Gazou,
  React
};