import { useStaticQuery, graphql } from "gatsby"

export const myHook = () => {
	const allData = useStaticQuery(
		graphql`
			query SiteMetaData {
				site {
					siteMetadata {
						title
						description
						siteUrl
					}
				}
				allMdx {
					edges {
						next {
							frontmatter {
								description
								title
								path
								date
								juni
								syurui
								key
							}
						}
						previous {
							frontmatter {
								description
								title
								path
								date
								juni
								key
								syurui
							}
						}
						node {
							frontmatter {
								title
								path
								description
								key
								date
								juni
								syurui
							}
						}
					}
				}
			}
   `
	)
	return allData
}