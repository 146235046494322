import React from "react"
import { Link } from "gatsby"

import Metal from '../components/metal'


export default () => (


<section class="kanaami butinuki t-center">

	<Metal/>

	<h2 class='moji_kage_koi'>E.L.V.Nミックス</h2>

	<h3 class='moji_kage_koi'>無料メールマガジン</h3>

	<div id="mc_embed_signup">

		<form action="https://tokyo.us18.list-manage.com/subscribe/post?u=0d71500e8a0f577cff8987305&amp;id=ed7c53b086" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" class="validate" novalidate>

			<div id="mc_embed_signup_scroll">

				<div class="mc-field-group">
					<label for="mce-EMAIL">メールアドレスを入力</label>
					<input type="email" name="EMAIL" class="required email" id="mce-EMAIL" required placeholder='メールアドレスを入力'/>
				</div>

				<div class="mc-field-group input-group">
					<input type="hidden" value="8" name="group[3401][8]" id="mce-group[3401]-3401-0"/>{/* グループの番号　普段：8、ファイル配布：512 */}
				</div>

				<div id="mce-responses" class="clear">
					<div class="response" id="mce-error-response" style={{display:`none`}}/>
					<div class="response" id="mce-success-response" style={{display:`none`}}/>
				</div>

				<div style={{position: `absolute`, left: -5000}} aria-hidden="true">
					<input type="text" name="b_0d71500e8a0f577cff8987305_ed7c53b086" tabindex="-1" value=""/>
				</div>
				<div class="clear">
					<input type="submit" value="無料で読む" name="subscribe" id="mc-embedded-subscribe" class="button"/>
				</div>

			</div>

		</form>
	</div>
	<span class='t-small'>※ ご入力いただいた個人情報は、<Link to="/osirase/privacy/">プライバシーポリシー</Link>に基づき、厳正に管理します。<br/>
			外部への共有は決して致しません。<br />
			<span class='t-bold'>不要の場合はいつでも解除可能です。</span></span>


</section>

)