import React from "react"
import Para from 'react-rellax'


export default () => (

<Para as={'img'} speed={-7} percentage={0.5}
	  class="kanaami_img lazyload para" alt=""
	  src="https://elvn.imgix.net/global/metal-l.png"
	  data-src="https://elvn.imgix.net/global/metal.png?w=1820&auto=format&fm=jpg&q=35&cs=srgb"
	  data-srcset="https://elvn.imgix.net/global/metal.png?w=1150&auto=format&fm=jpg&q=36&cs=srgb 1242w,
				  https://elvn.imgix.net/global/metal.png?w=1820&auto=format&fm=jpg&q=35&cs=srgb 1668w,
				  https://elvn.imgix.net/global/metal.png?w=1920&auto=format&fm=jpg&q=47&cs=srgb 1669w"/>

)