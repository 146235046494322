import React from 'react'
import { Link } from "gatsby"
import {myHook} from "@src/components/hook"


export default (props) => {

	const edges = myHook().allMdx.edges
	const index = edges.findIndex(edge => edge.node.frontmatter.key === props.kagi)
	const gaitou = edges[index]

	const title = gaitou.node.frontmatter.title
	const path = gaitou.node.frontmatter.path

	return (
		<Link to={path} class={props.tuika_class}>
		<span class='t-bold yajirusi'>{title}</span>
		</Link>
	)
}
