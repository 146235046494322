import React from "react"
import { Link } from "gatsby"

import KijiLink from "@components/kiji-link-only"

export default () => {

const nen = new Date().getFullYear()

	return (

<footer id="footer">

	<div class="prof seigen">
		<img class=     "lazyload prof_fan huwa" alt="イレヴンのロゴ"
			 src=       "https://elvn.imgix.net/global/fan.png?w=150&auto=format,compress&q=0"
			 data-srcset=  "https://elvn.imgix.net/global/fan.png?w=150&auto=format&dpr=1 1x,
					 	 	https://elvn.imgix.net/global/fan.png?w=150&auto=format&dpr=2 2x,
					 	 	https://elvn.imgix.net/global/fan.png?w=150&auto=format&dpr=3 3x"/>

		<h3 class="prof_elvn"><ruby>E.L.V.N<rt>イレヴン</rt></ruby></h3>

	<p>初めまして、E.L.V.Nのユーキです。</p>

<p>15歳から作曲を始めた、元プロ作曲家。写真スタジオチェーンのテーマ曲などをはじめ、世界で55万本売れたセガの「初音ミク Project DIVA」シリーズや、エグジットチューンズのメジャーCDシリーズ等に楽曲を提供。ネット上では、計60万回ほど再生。（<Link to='/'>トップページ</Link>では、主要な作品を聞くことができます）</p>

<p>現在は、<KijiLink kagi='mix' tuika_class='t-bold'>４時間でできる「常識を破壊するMIX」</KijiLink>を教えるプロのMIX講師として、超大手ゲーム会社の音楽スタッフ陣に公式にMIXを教えるなど、MIXの指導歴は３００人ほどに上る。</p>


<hr/>

		<p>ユーキ：<br/>
		作詞、作曲、ベース、ボーカル、Vocaloid調声、動画、Webデザイン</p>

		<p>ヨー：<br/>
		作曲、ギター、歯ぎしり、尻もち、霧吹き</p>

		<div class="sns">
			<a href="https://www.youtube.com/user/ELVNyuuki" target="_blank" rel="noopener noreferrer">
				<span class="icon youtube"></span></a>
			<a href="https://twitter.com/yuki11elvn" target="_blank" rel="noopener noreferrer">
				<span class="icon twitter"></span></a>
			<a href="https://www.nicovideo.jp/user/7716453/video" target="_blank" rel="noopener noreferrer">
				<span class="icon niconico"></span></a>
			<a href="https://soundcloud.com/elvn11eleven" target="_blank" rel="noopener noreferrer">
				<span class="icon soundcloud"></span></a>
		</div>
	</div>

	{/* 黒フッター */}
	<div class="kuro_bar" id="copyright">

		{/* 連絡先 */}
		<p>
			<span class="icon mail"></span> i💓elvn.tokyo (💓 → ＠)</p>

		{/* コピーライト */}
		<p>
					<span>© {nen} </span>
			<Link to="/">E.L.V.N</Link>
		</p>

		{/* 謝辞 */}
		<p class="syaji">

			<span class="para"></span>
			{/* クラスparaがないページだとjsにエラーが出るので */}
		</p>

	</div>
</footer>


)}